<template>
    <b-card title="project">
  
      <b-tabs>
        <b-tab
          active
          @click="$router.push(`/add-project-ngos/${id}`)"
        >
       
          <template #title>
            <feather-icon icon="PlusIcon" />
            <span>Add project</span>
          </template>
          <Addproject />
        </b-tab>
        <b-tab @click="$router.push(`/all-ngos-project/${id}`)">
          <template #title>
            <feather-icon icon="HomeIcon" />
            <span>  project</span>
          </template>
          <router-view />
          <!-- <AllAccreditation></AllAccreditation> -->
        </b-tab>
  
      </b-tabs>
  
    </b-card>
  </template>
  
  <script>
  
  import {
    BTabs, BTab, BCard,
  } from 'bootstrap-vue'
  import Addproject from './addproject.vue'
  import { useRouter } from '@core/utils/utils'
  
  export default {
    setup(props) {
  
      const { route } = useRouter()
      const { id } = route.value.params
      return {
        id,
      }
    },
    components: {
      BCard,
  
      BTabs,
      BTab,
  
      Addproject,
    },
    data() {
      return {
  
      }
    },
  }
  </script>
  